.extra-marker {
    /* background: url("../img/marker2.png");
    width: 33px;
    height: 44px; */
    background: url("../img/marker.png");
    width: 37px;
    height: 49px;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    text-align: center
}

.extra-marker-shadow {
    background: url("../img/markers_shadow.png") no-repeat 0 0;
    width: 36px;
    height: 16px
}

.extra-marker i {
    color: #fff;
    margin-left: 0px;
    margin-top: 11px;
    display: inline-block;
    font-size: 0px
}

.extra-marker i.icon {
    margin-right: 0;
    opacity: 1
}

.extra-marker-circle-blue {
    background-position: 0 0
}

/* .extra-marker .fa-number:before {
    content: attr(number);
    font-size: 11px;
}

.extra-marker .fa-number {
    margin-top: 11px;
} */

.extra-marker .fa-number:before {
    content: attr(number);
    font-size: 11px;
    white-space: break-spaces;
}

.extra-marker .fa-number {
    margin-top: 12px;
    line-height: 6px;
}

.extra-marker .fb-number:before {
    content: attr(number);
    font-size: 11px;
    /* color: white; */
    white-space: break-spaces;
    /* display: inline-block; */
}
.extra-marker .fb-number {
    margin-top: 10px;
    line-height: 6px;
}